@import "src/styles/_colors.scss";
@import "src/styles/_common.scss";

.sudoku-grid {
  margin-top: 3%;
  margin-bottom: 3%;
  // background-color: #333333d0;
  background-color: #44004E;
}

