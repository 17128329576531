@import 'src/styles/_common.scss';
.sudoku-panel {
  background-color: #dfe7fd;
  padding-bottom: 1rem;

  .clock {
    margin-top: 1rem;
    min-height: 20px; // line-height set by Grommet
    color: $light-grey-color;
  }

  .undo-button {
    margin: 10px;
    padding: 25px 13px;
    width: min-content;
    max-height: 20px;
    display: inline-flex;
    font-size: 22px;
    font-weight: bold;
    line-height: 20px;
    color: #5f0f40;
    border-radius: 18px;
    border: 2px solid #5f0f40;

    &.active {
      padding: 21px 9px;
      border: 4px solid #5f0f40;
      background-color: unset;
    }

    svg {
      align-self: center;
    }
  }

  .menu {

    .hamburger-icon {
      margin-top: 2px;
      margin-bottom: 6px;
    }

    svg:not(.hamburger-icon) {
      margin-bottom: 10px;
      margin-left: -5px;
    }
  }

  @media screen and (max-width: $medium-screen-max-width) {

    & {
      padding-bottom: 3rem
    }
    .undo-button {
      padding: 21px 5px;
    }

    .digit-bottom-row {
      margin-top: 10px;
    }

    .hamburger-icon {
      margin-bottom: 0;
      margin-top: 6px;
    }

    .clock {
      align-self: center;
      margin-top: 0.5rem;
    }

    .menu {
      margin-top: 1rem;
      align-self: center;
    }
  }
}