@import 'src/styles/_common.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

.cell {
  position: relative;
  flex: 1 0 calc(25%);
  background: white;
  transition: background-color 100ms ease-in-out;
  border: 1px solid #c3c3c3;

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.initial {
    background-color: #E2E2E2;
  }

  &:hover,
  &.active {
    // background-color: #FFEE7A;
    // background-color: #00D1B6;
    // background-color: #42FFE7;
    background-color: #1AC1AC;
  }

  &:focus {
    outline: none;
  }
}

.cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 36px;
}